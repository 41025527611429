<template>
  <div>
    <el-row type="flex" justify="space-around" :gutter="10">
      <GgdCard background="#6D87FD" title="顾问姓名" :content="adviserName"></GgdCard>
      <GgdCard background="#409EFF" title="今日流水" :content="amount"></GgdCard>
      <GgdCard background="#FF88A7" title="订单总量" :content="orderTotal" unit="个"></GgdCard>
      <GgdCard background="#7B5EEA" title="成单总量" :content="orderCount" unit="个"></GgdCard>
      <GgdCard background="#F19149" title="总拨打时长" :content="callTime"></GgdCard>
      <GgdCard background="#FF88A7" title="拨打量" :content="callCount" unit="个"></GgdCard>
      <GgdCard background="#7B5EEA" title="接通量" :content="callDailCount" unit="个"></GgdCard>
      <GgdCard background="#F19149" title="有效量" :content="callValid" unit="个"></GgdCard>
      <GgdCard background="#F19149" title="索取首咨总量" :content="consultCount" unit="个"></GgdCard>
      <el-col :span="4"></el-col>
    </el-row>

    <el-row type="flex" justify="start" :gutter="10">
      <el-col :span="10">
        <el-button-group>
          <el-button type="primary" size="mini" @click="doSearchByButton(0)">全部机会</el-button>
          <el-button type="primary" size="mini" @click="doSearchByButton(1)">首咨机会</el-button>
          <el-button type="primary" size="mini" @click="doSearchByButton(2)">个人机会</el-button>
          <el-button type="primary" size="mini" @click="doSearchByButton(3)">常规机会</el-button>
          <el-button type="primary" size="mini" @click="doSearchByButton(4)">库存机会</el-button>
          <el-button type="primary" size="mini" @click="doSearchByButton(5)">预约机会</el-button>
        </el-button-group>
      </el-col>
      <el-col :span="14">
        <el-form :inline="true">
          <el-form-item label="电话号码">
            <el-input size="mini" v-model="form.telephone" placeholder="电话号码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" icon="el-icon-search" @click="doSearch"></el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-row>
      <el-table :data="tableData" max-height="506px" style="width: 100%" stripe fit v-loading="loading">
        <el-table-column align="center" prop="opportunityId" fixed label="机会ID" width="100"></el-table-column>
        <el-table-column align="center" prop="telephone" fixed label="机会号码" width="120"></el-table-column>
        <el-table-column align="center" prop="opporType" label="类型" width="100">
          <template slot-scope="slot">
            <span v-if="slot.row.opporType === 1">首咨</span>
            <span v-if="slot.row.opporType === 2">个人</span>
            <span v-if="slot.row.opporType === 3">常规</span>
            <span v-if="slot.row.opporType === 4">库存</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="mediatorName" label="来源渠道" width="125"></el-table-column>
        <el-table-column align="center" label="拨打时间" width="180">
          <template slot-scope="slot">
            <span v-if="slot.row.status === 3">
              {{ JSON.parse(slot.row.content).dailTime }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="沟通时长" width="80">
          <template slot-scope="slot">
            {{ callTimes(slot.row) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="沟通状态" width="90">
          <template slot-scope="slot">
            {{ callStatus(slot.row) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="400">
          <template slot-scope="slot" v-if="slot.row.status === 3">
            <template v-if="JSON.parse(slot.row.content).callStatus ===1">
              <template v-if="JSON.parse(slot.row.content).recordingUrl.indexOf('http') != -1">
                <el-button type="primary" size="small" plain
                           @click="downLoads(JSON.parse(slot.row.content).recordingUrl)">下载录音
                </el-button>
              </template>
              <template slot-scope="slot" v-else>
                <audio controls="controls" :src="'https://call.qinglanx.com:8443/pscc/sounds/record/' + JSON.parse(slot.row.content).recordingUrl">
                </audio>
                <el-tooltip class="item" effect="dark" content="点击下载录音" placement="bottom">
                  <el-button type="success" circle plain icon="el-icon-download" style="vertical-align: top;margin-top: 8px"
                             @click="downloadRadio(`https://call.qinglanx.com:8443/pscc/sounds/record/${JSON.parse(slot.row.content).recordingUrl}`)">
                  </el-button>
                </el-tooltip>
              </template>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
      </el-pagination>
    </el-row>
  </div>

</template>

<script>
import * as api from "../../utils/api";
import {formatSeconds} from "../../utils/date-util";

import GgdCard from "../../components/GgdCard";
import {getUserId} from "../../utils/auth";

export default {
  name: "PersonInfo",
  components: {GgdCard},
  data() {
    return {
      tableData: [],
      amount: 0,
      orderTotal: 0,
      orderCount: 0,
      callTime: "",
      callCount: 0,
      consultCount: 0,
      callValid: 0,
      callDailCount: 0,
      form: {},
      type: 0,
      page: {current: 1, size: 10, total: 0},
      loading: true
    }
  },
  directives: {
    getLink: {
      // 指令的定义
      inserted: function (el, val) {
        el.src = "https://call.qinglanx.com:8443/pscc/sounds/record/" + JSON.parse(val.value).recordingUrl;
        // console.log(el.src);
        // console.log(JSON.parse(val.value).recordingUrl)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.page.size = val
      this.personInfo(this.type, this.accountId)
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.personInfo(this.type, this.accountId)
    },
    advisorHeadData() {
      api.advisorHeadData(this.accountId).then(res => {
        if (res.success) {
          let resEntity = res.data
          this.amount = resEntity.amount
          this.orderTotal = resEntity.orderTotal
          this.orderCount = resEntity.orderCount
          this.callTime = resEntity.callTime
          this.callCount = resEntity.callCount
          this.callDailCount = resEntity.callDailCount
          this.callValid = resEntity.callValid
          this.consultCount = resEntity.consultCount
        }
      })
    },
    doSearch: function () {
      if (!this.form.telephone) {
        this.$message.warning("查询电话号码不可能为空")
        return
      }
      this.personInfo(this.type, this.accountId, this.form.telephone);
    },
    doSearchByButton: function (type) {
      this.type = type
      this.personInfo(this.type, this.accountId);
    },
    personInfo: function (type, accountId, telephone) {
      api.personInfo(type, accountId, telephone, this.page.current, this.page.size).then(res => {
        let resEntity = res
        this.page.current = resEntity.current
        this.page.size = resEntity.size
        this.page.total = resEntity.total
        this.tableData = resEntity.records
        this.loading = false
      })
    },
    /**
     * 拨打状态
     * @param row
     */
    callStatus(row) {
      if (row.status === 3) {
        let content = row.content;
        let obj = JSON.parse(content);
        if (obj.callStatus === 0) {
          return "未接通"
        } else if (obj.callStatus === 1) {
          return "已接通"
        } else {
          return "--";
        }
      }
      return "--";
    },
    /**
     * 沟通时长
     * @param row
     */
    callTimes(row) {
      if (row.status === 3) {
        let content = row.content;
        let obj = JSON.parse(content);
        return formatSeconds(obj.timeCount);
      }
      return "--";
    },
    getUrl(link) {
      return "https://call.qinglanx.com:8443/pscc/sounds/record/" + JSON.parse(link).recordingUrl
    },
    /**
     * 下载录音
     */
    downLoads(url) {
      console.log(url)
      if (url.indexOf('http') == -1) {
        var httpurl = "https://call.qinglanx.com:8443/pscc/sounds/record/"
        url += httpurl + url
        console.log(url)
      }
      var a = document.createElement('a');
      var httpurl = url;
      var filename = 'XXX.zip';
      a.href = httpurl;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(httpurl);
    },
    /**
     * 下载录音
     * */
    downloadRadio(href) {
      let index = href.lastIndexOf("\/");
      let str = href.substring(index + 1, href.length);
      fetch(href).then(res => res.blob()).then(blob => {
        const a = document.createElement('a');
        document.body.appendChild(a)
        a.style.display = 'none'
        // 使用获取到的blob对象创建的url
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        // 指定下载的文件名
        a.download = str;
        a.click();
        document.body.removeChild(a)
        // 移除blob对象的url
        window.URL.revokeObjectURL(url);
      });
    },
  },
  computed: {
    adviserName: function () {
      return this.$route.query.accountName
    },
    accountId: function () {
      return this.$route.query.accountId
    }
  },
  mounted() {
    this.advisorHeadData()
    this.personInfo(this.type, this.accountId)
  }
}
</script>

<style scoped>

</style>
